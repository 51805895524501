import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./authConfig";
import { setupAxiosRequestInterceptor, setupAxiosResponseInterceptor } from './axiosInterceptors';

const msalInstance = new PublicClientApplication(msalConfig);

// Set up MSAL instance and Axios interceptors
msalInstance.initialize().then(() => {
  setupAxiosRequestInterceptor(msalInstance);
  setupAxiosResponseInterceptor(msalInstance);
});

// Ensure the active account is set
if (!msalInstance.getActiveAccount()) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}

// Handle MSAL login events
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    localStorage.setItem('account', account.username);
    const getToken = async () => {
      const token = await msalInstance.acquireTokenSilent({ ...loginRequest, account });
      localStorage.setItem('token', token.accessToken);
    };
    getToken();
  }
});

// Define routes using createBrowserRouter
const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    ),
  },
]);

// Render the application with a single RouterProvider instance
createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();