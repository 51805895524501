import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './scss/style.scss';
import { Provider } from 'react-redux';
import store from './store';
import {
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Lazy load the DefaultLayout component
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const MainContent = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [isInteractionHandled, setIsInteractionHandled] = useState(false);

  useEffect(() => {
    // Check if an interaction is already in progress to avoid multiple redirects
    instance.handleRedirectPromise()
      .then(() => setIsInteractionHandled(true))
      .catch((error) => console.error("Error handling redirect:", error));

    if (isInteractionHandled && !activeAccount) {
      // If no interaction is in progress and no active account, initiate login redirect
      instance.loginRedirect({
        scopes: ['api://c397f3f4-6ead-4c1e-9b6f-927ce4b5b848/wetsample'],
      });
    }
  }, [activeAccount, instance, isInteractionHandled]);

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Provider store={store}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="*" element={<DefaultLayout />} />
              </Routes>
            </Suspense>
          </Provider>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* Show a message or loading indicator if the user is being redirected */}
        <h5 className="card-title">Redirecting to sign-in...</h5>
      </UnauthenticatedTemplate>
    </div>
  );
};

// Main App component now renders MainContent and ToastContainer
const App = () => {
  return (
    <>
      <MainContent />
      <ToastContainer />
    </>
  );
};

export default App;