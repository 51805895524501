import React,{useState} from 'react';
import ReactDOM from 'react-dom';
//import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
//import { resolve } from 'path';
// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { loginRequest } from './authConfig';



export async function setupAxiosRequestInterceptor(msalInstance) {
    console.log(msalInstance);
    axios.interceptors.request.use(
        async (config) => {
            console.log('Request made to ' + config.url);
            let activeAccount = msalInstance.getActiveAccount();
            let attempts = 0;
            const MAX_ATTEMPTS = 3;
            const RETRY_INTERVAL = 500; // in milliseconds

            while (!activeAccount && attempts < MAX_ATTEMPTS) {
                await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL));
                activeAccount = msalInstance.getActiveAccount();
                attempts++;
            }

            if (!activeAccount) {
                console.log('No active account after waiting');
                return config;
            }

            try {
                const tokenResponse = await msalInstance.acquireTokenSilent({...loginRequest, account: activeAccount});
                if (tokenResponse) {
                    config.headers['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
                    //config.headers['Content-Type'] = 'application/json';
                    return config;
                }
            } catch (error) {
                console.error('Failed to acquire token', error);
                // Handle token acquisition errors gracefully
                // For example, initiate a new login flow or redirect to the login page
                // You can also throw an error to reject the request and handle it downstream
            }
            await new Promise(resolve => setTimeout(resolve, 3000));
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}



export async function setupAxiosResponseInterceptor(msalInstance) {
    const RETRY_LIMIT = 3;
    const RETRY_DELAY = 2000; // 2 seconds in milliseconds

    axios.interceptors.response.use(
        async (response) => {
            return response; // If the response was successful, return it
        },
        async (error) => {
            const originalRequest = error.config;
            // Check if the response was a 401 and we haven't already retried
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true; // Mark the request as retried
                //console.log(axios.defaults.headers.common['Authorization'])
                let retryCount = 0;
                while (retryCount < RETRY_LIMIT) {
                    try {
                        // Re-acquire token here. You might want to call `msalInstance.acquireTokenSilent` again.
                        const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
                        //localStorage.setItem('accessToken', tokenResponse.accessToken);
                        // Update the header
                        axios.defaults.headers.common['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
                        //axios.defaults.headers.common['Content-Type'] = 'application/json';
                        // Retry the original request
                        return await axios(originalRequest);
                    } catch (err) {
                        retryCount++; // Increment the retry count

                        if (retryCount < RETRY_LIMIT) {
                            console.log(`Retry attempt #${retryCount} failed. Retrying after ${RETRY_DELAY / 1000} seconds...`);
                            let retryDelay = RETRY_DELAY * retryCount;
                            await new Promise(resolve => setTimeout(resolve, retryDelay ));
                        }
                    }
                }

                // If execution reaches here, all retry attempts failed. Throw an error.
                msalInstance.loginRedirect(loginRequest);
                return Promise.reject(error);
            }

            // If response was not a 401 or we already retried, just reject the promise.
            return Promise.reject(error);
        }
    );
}
